import styles from "./register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import Header from "../components/Header";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    window.location.href = "/";
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="bg-login">
            <img src={require("../../statics/logo.png")} />
            <div className={styles.loginpage} style={{ minHeight: "600px" }}>
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={styles.formlogin}>
                            <div class="header">
                                <h2 class="title-center">Đăng nhập</h2>
                                <CloseIcon onClick={() => navigate("/")} />
                            </div>
                            <div className={styles.form_group}>
                                <input type="text" {...register("username")} placeholder="Tên đăng nhập" />
                                {errors.username ? <p>{errors.username.message}</p> : null}
                            </div>
                            <div className={styles.form_group}>
                                <input type={showPassword ? "text" : "password"} {...register("password")} placeholder="Mật khẩu" />
                                {showPassword ? <Visibility onClick={toggleShowPassword} /> : <VisibilityOff onClick={toggleShowPassword} />}
                                {errors.password ? <p>{errors.password.message}</p> : null}
                            </div>
                            {err ? <p>{err}</p> : null}
                            <div className={styles.form_group}>
                                <button className="btn-blue" type="submit" style={{ borderRadius: "5px" }}>
                                    Đăng nhập
                                </button>
                            </div>
                            <p style={{marginTop:"30px"}}>
                                Bạn chưa có tài khoản? <Link to="/register">Đăng ký ngay</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default Login;
