import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./header.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import PopupMenu from "./PopupMenu";
import moment from "moment";

const Header = ({ profileProp }) => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const location = useLocation();
    const [profile, setProfile] = useState(profileProp);
    useEffect(() => {
        setProfile(profileProp);
    }, [profileProp]);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [isOpen, setIsOpen] = useState(false);
    const [menu, setMenu] = useState(null);
    const openPopup = (num) => {
        setMenu(num);
        setIsOpen(true);
    };
    const closePopup = () => {
        setIsOpen(false);
    };
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
        const timer = setInterval(() => {
            setProfile(JSON.parse(localStorage.getItem("profile")));
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    /*useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/casino/create`);
    }, [profile]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    if (profile != res.data.data) {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    }
                })
                .catch((err) => localStorage.removeItem("user"));
        }, 5000);
        return () => clearInterval(interval);
    }, []);*/
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            swal("Thông báo", "Tên đăng nhập tối thiểu 6 kí tự", "warning");
            return;
        }
        if (!regex.test(data.username)) {
            swal("Thông báo", "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt", "warning");
            return;
        }
        if (data.password.length < 6) {
            swal("Thông báo", "Mật khẩu tối thiểu 6 kí tự", "warning");
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "Đồng ý"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    window.location.href = "/";
                });
            })
            .catch((err) => swal("Thông báo", err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!", "error"));
    };
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = moment().format("DD/MM/yyyy hh:mm:ss A [(GMT] Z)");
            setCurrentTime(now);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    const data = [
        {
            title: "Hot Games",
            icon: "navicon-hot",
            padding: "0",
            to: "/sicbo"
        },
        {
            title: "Thể Thao",
            icon: "navicon-sports",
            padding: "5px",
            to: "/sports"
        },
        {
            title: "Sòng Bài",
            icon: "navicon-live",
            padding: "0",
            to: "/casino"
        },
        {
            title: "Slots",
            icon: "navicon-egame",
            padding: "0",
            to: "/slots"
        },
        {
            title: "Đá Gà",
            icon: "navicon-cock",
            padding: "0",
            to: "/cockfighting"
        },
        {
            title: "Game Bài",
            icon: "navicon-chess",
            padding: "3px",
            to: "/pvp"
        },
        {
            title: "Bắn Cá",
            icon: "navicon-mpg",
            padding: "0",
            to: "/fishing"
        },
        {
            title: "Xổ Số",
            icon: "navicon-lotto",
            padding: "5px",
            to: "/lottery"
        },
        {
            title: "Esports",
            icon: "navicon-esports",
            padding: "0",
            to: "/esports"
        },
        {
            title: "Khuyến Mãi",
            icon: "navicon-promo",
            padding: "0",
            to: "/promotion"
        },
        {
            title: "VIP",
            icon: "navicon-vip",
            padding: "0",
            to: "https://www.896aevip888.com/page/vip"
        },
        {
            title: "Đại Lý",
            icon: "navicon-daily",
            padding: "0",
            to: "https://dlAE888.com/"
        },
        {
            title: "Tải App",
            icon: "navicon-app",
            padding: "0",
            to: "https://app-download.alltocon.com/AE888/"
        }
    ];
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("data");
        localStorage.removeItem("data1");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time;
        now.setTime(expireTime);
        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.href = "/";
    };
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    return (
        <>
            <header>
                <div className="header">
                    <div className="container">
                        <div className="main_header d-flex">
                            <div className="logo">
                                <Link to="/">
                                    <img alt="" src={require("../../image/logo.png")} />
                                </Link>
                            </div>
                            <div className="main_menu">
                                <div className="d-flex">
                                    <nav>
                                        <ul>
                                            {data.map((item, index) => (
                                                <li className={location.pathname === item.to ? "menu_item active" : "menu_item"} key={index}>
                                                    {index == 10 || index == 11 || index == 12 ? (
                                                        <a href={item.to} target="_blank">
                                                            <img src={require(`../../statics/${item.icon}.png`)} style={{ padding: item.padding }} />
                                                            <div className="item_title">{item.title}</div>
                                                        </a>
                                                    ) : (
                                                        <Link to={item.to}>
                                                            <img src={require(`../../statics/${item.icon}.png`)} style={{ padding: item.padding }} />
                                                            <div className="item_title">{item.title}</div>
                                                        </Link>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_menu">
                    <div className="container">
                        <div className="menu_login">
                            {profile ? (
                                <>
                                    <ul style={{ display: "flex", gap: "20px", fontSize: "15px", alignItems: "center" }}>
                                        <li>
                                            Người dùng: <span style={{ fontWeight: "bold" }}>{profile?.username}</span>
                                        </li>
                                        <li>
                                            Số dư: <span style={{ fontWeight: "bold" }}>{Math.floor(profile?.money).toLocaleString("vi-VN")}</span>
                                        </li>
                                    </ul>
                                </>
                            ) : (
                                <>
                                    <div stye={{ fontSize: "12px", color: "#888" }}>{currentTime}</div>
                                </>
                            )}
                            {profile ? (
                                <>
                                    <div className="menu-login">
                                        <ul>
                                            <li onClick={() => openPopup(1)} className="item">
                                                <span>Tài khoản</span>
                                            </li>
                                            {/*<li onClick={() => navigate("/invite")} className="item">
                                                    <span>Đại lý</span>
                                                </li>*/}
                                            <li onClick={() => openPopup(2)} className="item">
                                                <span>Nạp tiền</span>
                                            </li>
                                            <li onClick={() => openPopup(3)} className="item">
                                                <span>Rút tiền</span>
                                            </li>
                                            <li onClick={() => openPopup(7)} className="item">
                                                <span>Quy đổi</span>
                                            </li>
                                            <li onClick={() => openPopup(4)} className="item">
                                                <span>Lịch sử trò chơi</span>
                                            </li>
                                            <li onClick={logOut} className="item">
                                                <span>Đăng xuất</span>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="box-login">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="formlogin d-flex">
                                                <div>
                                                    <button onClick={() => navigate("/login")}>Đăng nhập</button>
                                                </div>
                                                <div>
                                                    <Link to="/register" className="btn">
                                                        Đăng ký
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <PopupMenu profile={profile} isOpen={isOpen} isClose={closePopup} menuActive={menu} getProfile={getProfile} />
        </>
    );
};

export default Header;
