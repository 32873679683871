import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./history.css";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

function HistoryTransfer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/chuyentien`, {})
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
    }, []);
    return (
        <>
            <div className="historyrecharge">
                <div className="top_title">
                    <div className="txt">Lịch sử chuyển ví</div>
                </div>
                {history ? (
                    <div className="main-history">
                        <div className="header-history">
                            <div style={{ width: "15%" }}>Loại</div>
                            <div style={{ width: "10%" }}>Số tiền</div>
                            <div style={{ width: "20%" }}>Chi tiết</div>
                            <div style={{ width: "15%" }}>Trạng thái</div>
                            <div style={{ width: "10%" }}>Số tiền trước</div>
                            <div style={{ width: "10%" }}>Số tiền sau</div>
                            <div style={{ width: "15%" }}>Thời gian</div>
                        </div>
                        {history?.map((item, key) => (
                            <>
                                <div className="content-history">
                                    <div style={{ width: "15%" }}>{item?.type_payment}</div>
                                    <div style={{ width: "10%" }}>{item?.money?.toLocaleString("vi-VN")}</div>
                                    <div style={{ width: "20%" }}>{item?.detail}</div>
                                    <div style={{ width: "15%" }}>
                                        <span className={`text_status ${item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"}`}>
                                            {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                        </span>
                                    </div>
                                    <div style={{ width: "10%" }}>{item?.before?.toLocaleString("vi-VN")}</div>
                                    <div style={{ width: "10%" }}>{item?.after?.toLocaleString("vi-VN")}</div>
                                    <div style={{ width: "15%" }}>{formatDate(new Date(item?.createdAt))}</div>
                                </div>
                            </>
                        ))}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </>
    );
}
export default HistoryTransfer;
