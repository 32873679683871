import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

function Transfer({ profile, getProfile }) {
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeOption, setActiveOption] = useState(1);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: sanh,
                type: activeOption,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!activeOption) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (activeOption == 2) {
                        swal("Thành công", `Chuyển quỹ ra thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào thành công!`, "success").then(() => window.location.reload());
                    }
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const handleChange = (e) => {
        setLoading(true);
        setSanh(e.target.value);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: e.target.value })
            .then((res) => {
                setBalance(res.data.data.balance);
                setLoading(false);
            })
            .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="recharge">
                {profile && (
                    <>
                        <div className="menu-recharge">
                            <div className={`menu-item-recharge ${activeOption == 1 ? "active" : ""}`} onClick={() => handleOptionClick(1)}>
                                <img src={require("../../../image/deposit.png")} />
                                <span>Nạp ví chính vào ví game</span>
                            </div>
                            <div className={`menu-item-recharge ${activeOption == 2 ? "active" : ""}`} onClick={() => handleOptionClick(2)}>
                                <img src={require("../../../image/atm.png")} />
                                <span>Rút ví game sang ví chính</span>
                            </div>
                        </div>
                        <div className="content-recharge">
                            {activeOption == 1 && (
                                <>
                                    <div className="top_title">
                                        <div className="txt">Chuyển tiền từ ví chính vào ví game</div>
                                    </div>
                                </>
                            )}
                            {activeOption == 2 && (
                                <>
                                    <div className="top_title">
                                        <div className="txt">Chuyển tiền từ ví game sang ví chính</div>
                                    </div>
                                </>
                            )}
                            {profile && (
                                <>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="content_bank">
                                            <h2>
                                                Số dư ví chính: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                                            </h2>
                                            <select onChange={handleChange} style={{ padding: "5px 10px", fontSize: "15px", borderColor: "#bdbdbd", marginRight: "10px" }}>
                                                <option hidden>Chọn sảnh</option>
                                                <option value="4">AG</option>
                                                <option value="79">BBIN</option>
                                                <option value="104">CMD</option>
                                                <option value="16">CQ9</option>
                                                <option value="27">DG</option>
                                                <option value="86">Evolution</option>
                                                <option value="142">FB</option>
                                                <option value="140">JILI</option>
                                                <option value="121">KingMaker</option>
                                                <option value="102">V8 Poker</option>
                                                <option value="123">King Poker</option>
                                                <option value="43">MG</option>
                                                <option value="39">PP</option>
                                                <option value="174">SABA</option>
                                                <option value="54">SBO</option>
                                                <option value="151">UG</option>
                                                <option value="118">WM</option>
                                                <option value="99">TF</option>
                                                <option value="112">SEXY</option>
                                                <option value="111">Playtech</option>
                                                <option value="28">Allbet</option>
                                                <option value="55">JDB</option>
                                                <option value="202">WS168</option>
                                                <option value="61">IMES</option>
                                                <option value="132">SV388</option>
                                            </select>
                                            {sanh && (
                                                <h2 style={{ display: "inline-block" }}>
                                                    Số dư: <span>{balance?.toLocaleString("vi-VN")}</span>
                                                </h2>
                                            )}
                                        </div>
                                        <div>
                                            <div className="input">
                                                <label>Nhập số tiền</label>
                                                <input
                                                    className="ipadd"
                                                    type="text"
                                                    value={newMoney}
                                                    {...register("money")}
                                                    onClick={() => setNewMoney(null)}
                                                    onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                                />
                                                <button type="submit" className="btn-submit" style={{ marginLeft: "10px" }}>
                                                    Xác nhận
                                                </button>
                                            </div>
                                            {activeOption == 1 && (
                                                <>
                                                    <div className="top_title2">
                                                        <div className="txt">
                                                            Số tiền nhận được trong game:{" "}
                                                            <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {activeOption == 2 && (
                                                <>
                                                    <div className="top_title2">
                                                        <div className="txt">
                                                            Số tiền nhận được vào ví chính:
                                                            <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <div style={{ display: "none" }}>
                                                <input className="ipadd" type="text" {...register("detail")} placeholder="Nhập mã giao dịch ngân hàng" defaultValue="Nạp" />
                                            </div>
                                        </div>
                                    </form>

                                    <div className="huongdan">
                                        <div className="title">Hướng dẫn</div>
                                        <ul>
                                            <li>Tỉ lệ quy đổi điểm: 1.000 điểm ví game = 1.000.000 điểm ví chính</li>
                                            <li>Vui lòng quy đổi điểm là số tiền chẵn (ví dụ 100.000), không nhập số lẻ (ví dụ 100.050)</li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
export default Transfer;
